import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,

  // MENU CUSTOME
  FormGuardians,
  FormStudent,
  FormMazer,
  PaymentAllSchool,
  PaymentDateYearMonths,
  ListGuardian,
  ListStudent,
  AllSchoolPayment,
  FormStaff,
  FormPrice,
  FormTeacherFee,
  FormTeacherCmmoission,
  Guardian,
  IncomeBySchool,
  NavVertical,
  GuardianAction,
  CreateDateEvent,
  StudentQRCode,
  TeacherQRCode,
  TeacherInstructorQRCode,
  ClassMonitorQRCode,
  AllReport,
  MonthlyReport,
  SemesterReport,
  YearlyReport,
  ParentQRCode,
  GuardianAttendance,
  GuardianAttendanceInClass,

  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  ExceptionGuardianPayment,
  TotalSchoolRecord,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    // {
    //   element: <MainLayout />,
    //   children: [
    //     {
    //       path: 'auth',
    //       children: [
    //         {
    //           path: 'login',
    //           element: (
    //             <GuestGuard>
    //               <LoginPage />
    //             </GuestGuard>
    //           ),
    //         },
    //         { path: 'login-unprotected', element: <LoginPage /> },
    //         { path: 'register-unprotected', element: <RegisterPage /> },
    //       ],
    //     },
    //   ],
    // },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        // {
        //   element: <CompactLayout />,
        //   children: [
        //     { path: 'reset-password', element: <ResetPasswordPage /> },
        //     { path: 'new-password', element: <NewPasswordPage /> },
        //     { path: 'verify', element: <VerifyCodePage /> },
        //   ],
        // },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/student" replace />, index: true },
            { path: 'student', element: <FormStudent /> },
            { path: 'addStudent', element: <FormGuardians /> },
            {
              path: 'guardian',
              children: [
                { path: 'profile', element: <UserProfilePage /> },
                { path: 'guardian_payment', element: <Guardian /> },
                { path: 'all_report', element: <AllReport /> },
                { path: 'monthly_report', element: <MonthlyReport /> },
                { path: 'semester_report', element: <SemesterReport /> },
                { path: 'yearly_report', element: <YearlyReport /> },
              ],
            },
            { path: 'staffInformation', element: <FormStaff /> },
            { path: 'mazer', element: <FormMazer /> },
          ],
        },

        {
          path: 'qr_code',
          children: [
            { path: 'student', element: <StudentQRCode /> },
            { path: 'teacher', element: <TeacherQRCode /> },
            { path: 'teacher_instructor', element: <TeacherInstructorQRCode /> },
            { path: 'class_monitor', element: <ClassMonitorQRCode /> },
            { path: 'guaridan', element: <ParentQRCode /> },
          ],
        },
        {
          path: 'excel',
          children: [
            { path: 'paymentAllSchool', element: <PaymentAllSchool /> },
            { path: 'paymentBySchool', element: <PaymentDateYearMonths /> },
          ],
        },
        {
          path: 'general',
          children: [
            { path: 'ListGuardian', element: <ListGuardian /> },
            { path: 'ListStudent', element: <ListStudent /> },
            { path: 'TotalSchoolRecord', element: <TotalSchoolRecord /> },
          ],
        },
        {
          path: 'revenue',
          children: [
            { path: 'allSchoolPayment', element: <AllSchoolPayment /> },
            { path: 'bySchool', element: <IncomeBySchool /> },
          ],
        },
        {
          path: 'feeSetting',
          children: [
            { path: 'formPrice', element: <FormPrice /> },
            { path: 'formTeacherFee', element: <FormTeacherFee /> },
            { path: 'formTeacherCmmoission', element: <FormTeacherCmmoission /> },
          ],
        },
        {
          path: 'school_activities',
          children: [
            { path: 'create_event_school', element: <CreateDateEvent /> },
            { path: 'guardian_presence', element: <GuardianAttendance /> },
            { path: 'guardian_presence_class', element: <GuardianAttendanceInClass /> },
          ],
        },
        {
          path: 'request_from_school',
          children: [{ path: 'exceptions_ payment', element: <ExceptionGuardianPayment /> }],
        },
      ],
    },

    {
      children: [
        { element: <Navigate to="/dashboard/user" replace />, index: true },
        { path: 'guardian', element: <FormGuardians /> },
        { path: 'student', element: <FormStudent /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
